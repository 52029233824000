//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/tables';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

//------------------------------------------------------------------
//	SITE-SPECIFIC ADMIN STYLES
//------------------------------------------------------------------
.mlmi-builder-row .layout[data-layout='shape_photo_dynamic'] {
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;

  .no-group > .acf-input {
    border-bottom: 1px solid #dfdfdf;

    .acf-field[data-width='50'] {
      &:nth-child(odd) {
        border-right: 1px solid #dfdfdf;
      }

      &:nth-child(even) {
        border-left: 1px solid #dfdfdf;
        margin-left: -1px;
        width: calc(100% + 1px);
      }
    }
  }
}

.wp-admin {
  .shape-selector {
    .acf-input {
      margin-left: -2px;
    }

    .acf-radio-list.acf-hl li {
      margin-right: 6px;
      margin-bottom: 3px;

      label {
        display: inline-block;
        width: 36px;
        height: 36px;
        line-height: 34px;
        vertical-align: text-top;
        border: 2px solid $white;
        text-align: center;

        &.selected {
          border: 2px solid $primary;
          background: $white;
        }
      }

      img {
        vertical-align: middle;
        width: 90%;
        height: auto;
      }

      input[type=radio] {
        display: none;
      }
    }

    &.height-based {
      .acf-radio-list li {
        .layout-icon {
          height: 20px;
          width: auto;
        }
      }
    }
  }

  .update-plugins.total-updates {
    display: none !important;
  }

  .item-inactive {
    opacity: .667;

    .item.column-item,
    .client.column-client {
      text-decoration: line-through;
    }
  }
}

table.table {
  th {
    text-align: left;
  }
}

.email-sent-status {
  .dashicons {
    margin: -1px 0 auto -2px;
  }
}
